import { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { Formik, Field, Form } from 'formik';
import db from "./firebase";
import { Link, useNavigate } from "react-router-dom";
import headerImage from "./assets/header-image.jpeg";
import upiImage from "./assets/frat-upi-card-lubaib.png";

const upiLink = "";
const upiNumber = "";

function Register() {
    const navigate = useNavigate();
    const [languages] = useState(["English"]);
    const [userLanguage, setUserLanguage] = useState("English");

    const indianStates = [
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli and Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"
    ];

    const selectLanguage = (e) => {
        const userLanguage = e.target.value;
        setUserLanguage(userLanguage);
    }

    return (
        <div className="max-w-lg m-auto p-4 text-center min-h-screen">
            <header>
                <img className="w-100 p-1 max-w-full rounded m-auto" src={headerImage} alt="Join Fraternity" />
            </header>
            <div className="divider">Membership Form</div>

            <label className="label mx-auto max-w-xs">
                <span className="label-text">Select your Language:</span>
            </label>
            <select className="select w-full max-w-xs" value={userLanguage}
                onChange={selectLanguage}>
                <option disabled selected>Language</option>
                {languages.map((language, i) =>
                    <option key={i}>{language}</option>
                )}
            </select>
            <div className="m-8" />
            <div className="card bg-[#f4f4f4] text-neutral">
                <div className="card-body text-left">
                    <Formik
                        initialValues={{
                            name: "",
                            dob: "",
                            gender: "",
                            mobile: "",
                            email: "",
                            state: "",
                            district: "",
                            institution: "",
                            course: "",
                            yearOfStudy: "",
                            hasPaidFee: false
                        }}
                        onSubmit={async (values) => {
                            const docRef = await addDoc(collection(db, "registrations"), {
                                ...values,
                                timestamp: serverTimestamp()
                            });
                            console.log("Document written with ID: ", docRef.id);
                            localStorage.setItem('memberId', docRef.id);
                            localStorage.setItem('memberName', values.name);
                            navigate("/success");
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form className="text-gray-600">
                                <h2 className="text-lg font-semibold mb-4">Personal Details</h2>
                                
                                <label htmlFor="name">Full Name: *</label>
                                <Field name="name" placeholder="Enter your full name" className="mt-2 input w-full text-white mb-4" required />
                                
                                <label htmlFor="dob">Date of Birth: *</label>
                                <Field name="dob" type="date" className="mt-2 input w-full text-white mb-4" required />
                                
                                <label htmlFor="gender">Gender: *</label>
                                <Field as="select" name="gender" className="mt-2 select w-full text-white mb-4" required>
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </Field>
                                
                                <label htmlFor="mobile">Phone Number: *</label>
                                <Field name="mobile" placeholder="Phone (10 digits)" className="mt-2 input w-full text-white mb-4" type="tel" pattern="[0-9]{10}" required />
                                
                                <label htmlFor="email">Email Address: *</label>
                                <Field name="email" type="email" placeholder="Enter your email" className="mt-2 input w-full text-white mb-4" required />
                                
                                <label htmlFor="state">State: *</label>
                                <Field as="select" name="state" className="mt-2 select w-full text-white mb-4" required>
                                    <option value="">Select State</option>
                                    {indianStates.map((state, index) => (
                                        <option key={index} value={state}>{state}</option>
                                    ))}
                                </Field>
                                
                                <label htmlFor="district">District: *</label>
                                <Field name="district" placeholder="Enter your district" className="mt-2 input w-full text-white mb-4" required />

                                <div className="divider"></div>
                                
                                <h2 className="text-lg font-semibold mb-4">Educational Details</h2>
                                
                                <label htmlFor="institution">Institution:</label>
                                <Field name="institution" placeholder="Enter your institution name" className="mt-2 input w-full text-white mb-4" />
                                
                                <label htmlFor="course">Current Course/Programme:</label>
                                <Field name="course" placeholder="Enter your current course" className="mt-2 input w-full text-white mb-4" />
                                
                                <label htmlFor="yearOfStudy">Year of Study:</label>
                                <Field name="yearOfStudy" placeholder="Enter your year of study" className="mt-2 input w-full text-white mb-4" />

                                <p className="my-4 text-xs">Please double check the details are correct before submitting.</p>

                                {upiLink !== "" ?
                                    <a href={upiLink} disabled={isSubmitting}>
                                        <img className="mt-8 w-100 max-w-full m-auto shadow-xl" src={upiImage} alt="scan to pay" />
                                    </a>
                                    : upiNumber !== "" ?
                                        <img onClick={() => { navigator.clipboard.writeText(upiNumber); alert("Number copied. Now pay with Google pay."); }} className="mt-8 w-100 max-w-full m-auto shadow-xl" src={upiImage} alt="scan to pay" />
                                        :
                                        <img className="mt-8 w-100 max-w-full m-auto shadow-xl" src={upiImage} alt="scan to pay" />
                                }

                                <div className="mt-8 flex mb-8">
                                    <Field name="hasPaidFee" className="checkbox bg-gray-900" type="checkbox" />
                                    &nbsp; Paid the above Membership Fee
                                </div>

                                <div className="card-actions justify-center">
                                    <button type="submit" className="btn bg-sky-600 border-none text-white shadow-lg w-full" disabled={isSubmitting}>
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            <footer className="footer footer-center pt-16 text-base-content">
                <div>
                    <Link to="/" className="btn btn-sm btn-ghost">🏠 Home</Link>
                    <p>Help: <a href="tel:+919870367010">+91 9870367010</a></p>
                </div>
            </footer>
        </div>
    );
}

export default Register;